import React, { useCallback, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { InputAdornment, Tab, Tabs } from '@mui/material'
import debounce from 'lodash.debounce'
import SearchIcon from '@mui/icons-material/Search'

import * as S from './styles'
import ManageContent from '../ManageContent'
import List from '../List'
import CustomTabPanel from '../../../../components/Dashboard/CustomTabPanel'
import PageHeader from '../../../../components/Dashboard/PageHeader'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const ManageContentWrapper = ({ isUserContent = true }) => {
	const [createNewContent, setCreateNewContent] = useState(false)
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const [searchValue, setSearchValue] = useState('')
	const searchValueRef = useRef('')

	// eslint-disable-next-line
	const debounceContent = useCallback(
		debounce((value) => {
			setSearchValue(value)
		}, 500),
		[]
	)

	const handleContentSearch = useCallback(
		({ target }) => {
			debounceContent(target.value)
		},
		[debounceContent]
	)

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	return (
		<>
			<S.Wrapper>
				{isUserContent ? (
					<>
						<PageHeader
							title="Meus Conteúdos"
							contentTypeSlug="content"
							setCreateNewContent={setCreateNewContent}
						/>
						<>
							<S.TabsContainer>
								<Tabs
									value={tabIndexValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
								>
									<Tab label="Gerenciar" {...a11yProps(0)} />
									<Tab
										label="Ver Publicados"
										{...a11yProps(1)}
									/>
								</Tabs>
								<S.StyledInput
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
									variant="outlined"
									onChange={handleContentSearch}
									placeholder="Pesquisar conteúdo..."
								/>
							</S.TabsContainer>
							<CustomTabPanel value={tabIndexValue} index={0}>
								<ManageContent
									searchValue={searchValue}
									searchValueRef={searchValueRef}
									isUserContent={isUserContent}
									createNewContent={createNewContent}
									setCreateNewContent={setCreateNewContent}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={tabIndexValue} index={1}>
								<List
									searchValue={searchValue}
									searchValueRef={searchValueRef}
								/>
							</CustomTabPanel>
						</>
					</>
				) : (
					<>
						<S.StyledInput
							fullWidth
							inputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								)
							}}
							variant="outlined"
							onChange={handleContentSearch}
							placeholder="Pesquisar conteúdo..."
						/>
						<ManageContent
							searchValue={searchValue}
							searchValueRef={searchValueRef}
							isUserContent={isUserContent}
							createNewContent={createNewContent}
							setCreateNewContent={setCreateNewContent}
						/>
					</>
				)}
			</S.Wrapper>
		</>
	)
}

export default withRouter(ManageContentWrapper)
