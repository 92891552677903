import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import xor from 'lodash.xor'
import Modal from '@mui/material/Modal'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import * as S from './styles'
import { getSteps, stepInputs } from './steps'
import { isArrayEqual } from '../../../../utils/functions'
import { collectionTypeSlugs } from '../../../../app/_constants'
import { collectionsActions } from '../../../../app/_actions'

import EditCommonFields from '../../EditSteps/EditCommonFields'
import EditCollectionExtras from '../../EditSteps/EditCollectionExtras'
import EditDialogFooter from '../../EditDialogFooter'
import { STATUS } from '../../../../constants'

const EditCollectionDialog = ({
	collectionTypeObject,
	handleClose,
	initialCollectionId,
	isModalOpen,
	onUpdate = null
}) => {
	const dispatch = useDispatch()
	const steps = getSteps(collectionTypeObject)

	const [imageSrc, setImageSrc] = useState(null)
	const [croppedImage, setCroppedImage] = useState(null)
	const [inputs, setInputs] = useState(stepInputs)
	const [step, setStep] = useState(0)
	const [messageError, setMessageError] = useState(null)
	const [showFooter, setShowFooter] = useState(true)
	const messageErrorRef = useRef(null)

	const {
		object: collection,
		loadingImage,
		loading
	} = useSelector(
		// @ts-ignore
		(state) => state.collections
	)

	// Submit step 0:
	const submitCommonFields = (
		currCollection,
		currInputs,
		currImageSrc,
		currCroppedImage
	) => {
		async function saveImage(collectionId) {
			if (currImageSrc && currCroppedImage) {
				const blob = await new Promise((resolve) => {
					currCroppedImage.canvas.toBlob((file) => {
						resolve(file)
					})
				})
				dispatch(
					collectionsActions.setCover(
						collectionId,
						blob,
						onUpdate,
						(error) => {
							setMessageError(error)
							setStep(0)
						}
					)
				)
			}
		}

		const { title, description } = currInputs
		if (!title) {
			setMessageError('Informe o título do conteúdo')
			return false
		}
		if (!description) {
			setMessageError('Preencha a descrição do conteúdo')
			return false
		}
		if (!currCollection.cover_id && !currImageSrc) {
			setMessageError('Selecione uma imagem de capa')
			return false
		}

		if (!currCollection.id) {
			// Create first collection
			dispatch(
				collectionsActions.createCollectionByType(
					collectionTypeObject.slug,
					{
						title,
						description,
						status: STATUS.RASCUNHO
					},
					(data) => {
						if (currImageSrc) {
							saveImage(data.id)
						}
						if (onUpdate) {
							onUpdate()
						}
					},
					(error) => {
						setMessageError(error)
						setStep(0)
					}
				)
				// @ts-ignore
			)
			return true
		}

		if (
			currCollection.title !== title ||
			currCollection.description !== description
		) {
			dispatch(
				collectionsActions.updateCollection(
					currCollection.id,
					{
						title,
						description,
						status: [
							STATUS.AGUARDANDO_APROVACAO,
							STATUS.APROVADO
						].includes(currCollection.status)
							? 4
							: currCollection.status
					},
					onUpdate,
					(error) => {
						setMessageError(error)
						setStep(0)
					}
				)
			)
		}
		if (currImageSrc) {
			saveImage(currCollection.id)
		}
		return true
	}

	// Submit step 1:
	// const submitCollectionContents = (currCollection, currInputs) => {
	// 	const { content_indications } = currInputs
	// 	if (!content_indications || content_indications.length === 0) {
	// 		setMessageError(
	// 			`Escolha pelo menos um conteúdo para ${
	// 				collectionTypeObject.genre
	// 			} ${collectionTypeObject.name.toLowerCase()}`
	// 		)
	// 		return false
	// 	}

	// 	if (
	// 		JSON.stringify(
	// 			currCollection.content_indications.map((item) => item.id).sort()
	// 		) !== JSON.stringify(content_indications.sort())
	// 	) {
	// 		dispatch(
	// 			collectionsActions.updateCollection(
	// 				currCollection.id,
	// 				{ content_indications },
	// 				onUpdate,
	// 				(error) => {
	// 					setMessageError(error)
	// 					setStep(1)
	// 				}
	// 			)
	// 		)
	// 	}
	// 	return true
	// }

	// Submit step 1:
	const submitCollectionExtras = (currCollection, currInputs) => {
		const preparedInputs = { ...currInputs }
		const keys = ['language_id', 'themes']
		for (const fieldKey of keys) {
			if (currInputs[fieldKey] && Array.isArray(currInputs[fieldKey])) {
				preparedInputs[fieldKey] = [
					...currInputs[fieldKey].map((input) => input.id)
				]
			}
		}
		const {
			language_id,
			themes,
			keywords,
			date_start,
			date_end,
			curso_type,
			curso_workload,
			curso_program,
			projeto_financers,
			projeto_url
		} = preparedInputs

		let hasEmptyRequiredField = !keywords.length || !themes.length

		switch (collectionTypeObject.slug) {
			case collectionTypeSlugs.SERIE:
				break
			case collectionTypeSlugs.CURSO:
				if (!curso_type) {
					hasEmptyRequiredField = true
				}
				break
			case collectionTypeSlugs.PROJETO:
				break
		}
		if (hasEmptyRequiredField) {
			setMessageError(
				`Preencha os campos obrigatórios para que ${
					collectionTypeObject.genre === 'a' ? 'sua' : 'seu'
				} ${collectionTypeObject.name.toLowerCase()} possa ser encontrad${
					collectionTypeObject.genre
				} nos mecanismos de busca do portal.`
			)
			if (messageErrorRef.current) {
				messageErrorRef.current.scrollIntoView({
					behavior: 'smooth'
				})
			}
			return false
		}

		if (
			xor(currCollection.language_ids, language_id).length ||
			xor(
				currCollection.themes.map((theme) => theme.id),
				themes
			).length ||
			(currCollection.keywords &&
				!isArrayEqual(keywords, currCollection.keywords.split(', '))) ||
			currCollection.date_start !== date_start ||
			currCollection.date_end !== date_end ||
			currCollection.curso_type !== curso_type ||
			currCollection.curso_workload !== curso_workload ||
			currCollection.curso_program !== curso_program ||
			(currCollection.projeto_financers &&
				!isArrayEqual(
					projeto_financers,
					currCollection.projeto_financers.split(', ')
				)) ||
			currCollection.projeto_url !== projeto_url
		) {
			dispatch(
				collectionsActions.updateCollection(
					currCollection.id,
					{
						language_id,
						themes,
						keywords: keywords ? keywords.join(', ') : null,
						date_start: date_start
							? date_start.format('YYYY-MM-DD')
							: null,
						date_end: date_end
							? date_end.format('YYYY-MM-DD')
							: null,
						curso_type: curso_type ? curso_type.id : null,
						curso_workload,
						curso_program,
						projeto_financers: projeto_financers
							? projeto_financers.join(', ')
							: null,
						projeto_url,
						status: [
							STATUS.AGUARDANDO_APROVACAO,
							STATUS.APROVADO
						].includes(currCollection.status)
							? 4
							: currCollection.status
					},
					onUpdate,
					(error) => {
						setMessageError(error)
						setStep(1)
					}
				)
			)
		}
		return true
	}

	const handleSubmit = (stepToSubmit) => {
		if (loading || loadingImage) {
			return
		}
		if (stepToSubmit > 0 && !collection.id) {
			setMessageError(
				'Alguma falha impediu o conteúdo ser criado. Por favor, volte ao passo anterior e tente novamente.'
			)
			return
		}
		let success = false
		switch (stepToSubmit) {
			case 0:
				success = submitCommonFields(
					collection,
					inputs,
					imageSrc,
					croppedImage
				)
				break
			// case 1:
			// 	success = submitCollectionContents(collection, inputs)
			// 	break
			case 1:
				success = submitCollectionExtras(collection, inputs)
				if (success) {
					setMessageError(null)
					// if (setSelectedCollectionId) {
					// 	openPublishModal()
					// 	setSelectedCollectionId(collection.id)
					// 	setStep(0)
					// 	return
					// }
					handleCloseModal()
				}
				return
		}

		if (success) {
			setMessageError(null)
			setStep(stepToSubmit + 1)
		}
	}

	const handleChangeInputs = useCallback(({ target }) => {
		const { name, value: newValuesRaw } = target
		setInputs((prevInputs) => ({ ...prevInputs, [name]: newValuesRaw }))
		setMessageError(null)
	}, [])

	useEffect(() => {
		if (collection.id) {
			setInputs((prevInputs) => ({
				...prevInputs,
				collection_type: collection.collection_type,
				title: collection.title || '',
				description: collection.description || '',
				content_indications: collection.content_indications.map(
					({ content_indication }) => content_indication.id
				),
				contributors: collection.contributors
					? collection.contributors.split(', ')
					: [],
				language_id: collection.language_ids
					? collection.language_ids.map((languageId) => {
							return { id: languageId }
					  })
					: [],
				themes: collection.themes,
				keywords: collection.keywords
					? collection.keywords.split(', ')
					: [],
				date_start: collection.date_start
					? dayjs(collection.date_start)
					: null,
				date_end: collection.date_end
					? dayjs(collection.date_end)
					: null,
				curso_type: collection.curso_type,
				curso_workload: collection.curso_workload,
				curso_program: collection.curso_program,
				projeto_financers: collection.projeto_financers
					? collection.projeto_financers.split(', ')
					: [],
				projeto_url: collection.projeto_url
			}))
			return
		}
		setInputs(stepInputs)
	}, [collection])

	useEffect(() => {
		if (initialCollectionId) {
			dispatch(collectionsActions.getCollectionById(initialCollectionId))
			return
		}

		setImageSrc(null)
		setCroppedImage(null)
	}, [dispatch, initialCollectionId])

	const handleCloseModal = useCallback(() => {
		setCroppedImage(null)
		setStep(0)
		handleClose()
	}, [handleClose])

	if (!isModalOpen) {
		return <></>
	}

	const cover = collection
		? { filename: collection.cover_filename, image: collection.cover_id }
		: null

	return (
		<Modal open={isModalOpen} onClose={handleCloseModal}>
			<S.Panel>
				<S.Wrapper>
					<S.StepperContainer>
						<Stepper activeStep={step}>
							{steps.map((step) => (
								<Step key={step.key}>
									<StepButton color="inherit">
										{step.title}
										{collection.title
											? ` "${collection.title}"`
											: ''}
										<Typography variant="caption">
											{step.description}
										</Typography>
									</StepButton>
								</Step>
							))}
						</Stepper>
					</S.StepperContainer>
				</S.Wrapper>
				<S.StepContainer>
					{step === 0 && (
						<EditCommonFields
							collectionTypeObject={collectionTypeObject}
							inputs={inputs}
							onChange={handleChangeInputs}
							imageSrc={imageSrc}
							setImageSrc={setImageSrc}
							setCroppedImage={setCroppedImage}
							currentCoverImage={croppedImage || cover}
							messageError={messageError}
							setShowFooter={setShowFooter}
						/>
					)}

					{/*step === 1 && (
						<EditCollectionContents
							collectionTypeObject={collectionTypeObject}
							contentIndicationIds={inputs.content_indications}
							onChange={handleChangeInputs}
							messageError={messageError}
						/>
					)*/}

					{step === 1 && (
						<EditCollectionExtras
							collectionTypeObject={collectionTypeObject}
							inputs={inputs}
							onChange={handleChangeInputs}
							messageError={messageError}
							messageErrorRef={messageErrorRef}
						/>
					)}
				</S.StepContainer>
				<EditDialogFooter
					showFooter={showFooter}
					handleCloseModal={handleCloseModal}
					step={step}
					setStep={setStep}
					handleSubmit={handleSubmit}
					loading={loading}
					steps={steps}
				/>
			</S.Panel>
		</Modal>
	)
}

export default EditCollectionDialog
