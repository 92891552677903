import React from 'react'
import * as S from './styles'
import { ContentBlock } from '../../../eita-react-components'

function CollectionContentIndicationsList({ item, onCollectionContentClick }) {
	if (!item || !item.content_indications) {
		return <></>
	}
	const typeGenre = item.collection_type.genre
	const typeName = item.collection_type.name
	// const content_indications = item.content_indications.sort((a, b) =>
	// 	a.title.localeCompare(b.title)
	// )
	return (
		<S.ContentBlockWrapper>
			<S.InfoWrapper style={{ paddingLeft: '8px' }}>
				<S.InfoTitle>{item.content_indications.length} </S.InfoTitle>
				<S.InfoText>
					conteúdos n{typeGenre} {typeName.toLowerCase()}
				</S.InfoText>
			</S.InfoWrapper>
			{item.content_indications.map(
				({ content_indication: contentIndication }, idx) => {
					return (
						<ContentBlock
							key={`ci_${contentIndication.id}${idx}`}
							id={contentIndication.id}
							title={contentIndication.title}
							description={contentIndication.description}
							img={contentIndication.cover_id + '.thumb'}
							onClick={() => {
								if (onCollectionContentClick) {
									onCollectionContentClick(
										contentIndication.friendly_url
									)
								}
							}}
							contentOrCollectionType={
								contentIndication.content_type
							}
						/>
					)
				}
			)}
		</S.ContentBlockWrapper>
	)
}

export default CollectionContentIndicationsList
