import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import Avatar from '@mui/material/Avatar'

import * as S from './styles'
import FormatCellValue from '../../../../components/DataTable/FormatCellValue'
import ActionColumnTable from '../../../../components/DataTable/ActionColumnTable'
import CustomPagination from '../../../../components/Dashboard/CustomPagination'
import { searchActions } from '../../../../app/_actions'
import EditContentWrapper from '../../../../components/Dashboard/EditContent/EditContentWrapper'
import {
	getJustification,
	getStatusClassName,
	isMobile
} from '../../../../utils/functions'
import { useTranslation } from 'react-i18next'
import { GridRow } from '@mui/x-data-grid'

const defaultSortModel = {
	field: 'created_at',
	sort: 'desc'
}

function ManageContent({
	searchValue,
	searchValueRef,
	isUserContent,
	createNewContent,
	setCreateNewContent,
	checkboxSelection = false,
	selectedIds = null,
	setSelectedIds = null,
	visibleFields = null,
	skipPublishDialogOnFinish = false,
	onClose = null,
	onUpdate = null,
	isDragDropEnabled = false,
	onDragEnd = null
}) {
	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [selectedContentId, setSelectedContentId] = useState(null)
	const [sortModel, setSortModel] = useState(defaultSortModel)
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
	})
	const [rowSelectionModel, setRowSelectionModel] = useState([])
	const { i18n } = useTranslation()

	const dispatch = useDispatch()
	const { contents } = useSelector(
		// @ts-ignore
		(state) => state.search
	)
	const { results, totalResults, loading } = contents
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const handleOnUpdate = useCallback(
		(data) => {
			if (onUpdate) {
				onUpdate({ data, selectedContentId })
			}
		},
		[selectedContentId, onUpdate]
	)

	const getData = useCallback(() => {
		if (user) {
			if (
				searchValue !== searchValueRef.current &&
				paginationModel.page > 0
			) {
				setPaginationModel({
					page: 0,
					pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
				})
				searchValueRef.current = searchValue
				return
			}
			const params = {
				q: searchValue,
				contents_only: true
			}
			if (isUserContent) {
				params.user_id = user.id
			}
			if (selectedIds && selectedIds.length > 0) {
				params.selected = selectedIds.map((item) => ({
					...item,
					id: `content_indications__${item.id}`
				}))
			}
			dispatch(
				searchActions.getFilteredResults(
					params,
					paginationModel.page + 1,
					paginationModel.pageSize,
					i18n.language,
					sortModel?.field,
					sortModel?.sort,
					handleOnUpdate
				)
			)
			searchValueRef.current = searchValue
		}
	}, [
		dispatch,
		paginationModel,
		sortModel?.field,
		sortModel?.sort,
		user,
		searchValue,
		isUserContent,
		searchValueRef,
		i18n.language,
		handleOnUpdate,
		selectedIds
	])

	const onLocalDragEnd = (result) => {
		if (!result.destination) return
		if (onDragEnd) {
			onDragEnd({
				movedItemId: result.draggableId,
				newIndex: result.destination.index
			})
		}
	}

	const handleSortModelChange = useCallback((sort) => {
		if (sort[0] && sort[0].field && sort[0].sort) {
			setSortModel(sort[0])
		} else {
			setSortModel(defaultSortModel)
		}
	}, [])

	const handleEdit = (item) => {
		setSelectedContentId(item.id)
	}

	const onRowSelectionModelChange = (newSelectedIdsUnordered) => {
		if (selectedIds === null || setSelectedIds === null) {
			return
		}
		const newSelectedIds = selectedIds.filter(({ id }) =>
			newSelectedIdsUnordered.includes(id)
		)
		let currentOrder =
			newSelectedIds.length > 0
				? Math.max(...newSelectedIds.map(({ order }) => order))
				: 0
		for (const newSelectedId of newSelectedIdsUnordered) {
			if (!newSelectedIds.find(({ id }) => id === newSelectedId)) {
				currentOrder += 1
				newSelectedIds.push({ id: newSelectedId, order: currentOrder })
			}
		}
		setSelectedIds(newSelectedIds)
	}

	const resetContentId = useCallback(() => {
		setContentModalOpen(false)
		setSelectedContentId(null)
	}, [])

	let columns = isMobile()
		? [
				{
					field: 'actions',
					headerName: '',
					type: 'actions',
					getActions: (params) => [
						<ActionColumnTable
							key={params.row.index}
							item={params.row}
							onEdit={handleEdit}
							// checkStatus
						/>
					],
					width: 6
				},
				{
					field: 'title',
					headerName: 'Título',
					flex: 1
				},
				{
					field: 'status',
					headerName: 'Sit.',
					valueFormatter: ({ value }) =>
						FormatCellValue({
							value,
							status: true
						})
				}
		  ]
		: [
				{
					field: 'actions',
					headerName: '',
					type: 'actions',
					getActions: (params) => [
						<ActionColumnTable
							key={params.row.index}
							item={params.row}
							onEdit={handleEdit}
							// checkStatus
						/>
					],
					flex: 0.4
				},
				{
					field: 'title',
					headerName: 'Título',
					minWidth: 200,
					flex: 1
				},
				{
					field: 'status',
					headerName: 'Situação',
					valueFormatter: ({ value }) =>
						FormatCellValue({
							value,
							status: true
						}),
					minWidth: 150
				},
				{
					field: 'reprove_justification',
					headerName: 'Justificativa',
					valueFormatter: (props) => {
						const row = props.api.getRow(props.id)
						return getJustification(row)
					},
					minWidth: 150,
					sortable: false
				},
				{
					field: 'cover_id',
					headerName: 'Imagem',
					renderCell: ({ value }) =>
						value ? (
							<Avatar
								src={value}
								alt="imagem"
								sx={{ width: 64, height: 64 }}
							/>
						) : (
							<>-</>
						),
					sortable: false
				},
				{ field: 'author', headerName: 'Autor', minWidth: 200 },
				{
					field: 'keywords',
					headerName: 'Palavras-chave',
					flex: 0.5,
					minWidth: 200
				},
				{
					field: 'user',
					headerName: 'Usuária',
					flex: 0.5,
					minWidth: 150,
					valueGetter: (params) =>
						params.row.user ? params.row.user.name : ''
				},
				{
					field: 'organization',
					headerName: 'Instituição',
					flex: 0.5,
					minWidth: 150,
					valueGetter: (params) =>
						params.row.user && params.row.user.organization
							? params.row.user.organization.name
							: ''
				},
				{
					field: 'how_cite',
					headerName: 'Como citar',
					flex: 0.5,
					minWidth: 150,
					sortable: false
				},
				{
					field: 'year',
					headerName: 'Ano de publicação',
					flex: 0.4,
					minWidth: 150
				},
				{
					field: 'created_at',
					headerClassName: 'super-app-theme--header',
					headerName: 'Criado em',
					valueFormatter: ({ value }) =>
						FormatCellValue({
							value,
							format: 'DD/MM/YYYY HH:MM'
						}),
					flex: 0.5,
					minWidth: 150
				},
				{
					field: 'updated_at',
					headerName: 'Última atualização',
					valueFormatter: ({ value }) =>
						FormatCellValue({
							value,
							format: 'DD/MM/YYYY HH:MM'
						}),
					flex: 0.5,
					minWidth: 150
				}
		  ]

	if (isUserContent) {
		// @ts-ignore
		columns = columns.filter(
			(col) => !['user', 'organization'].includes(col.field)
		)
	}
	if (checkboxSelection) {
		// @ts-ignore
		columns = columns.filter((col) => col.field !== 'actions')
	}

	if (visibleFields) {
		// @ts-ignore
		columns = columns.filter((col) => visibleFields.includes(col.field))
	}

	const ManageContentTable = () => (
		<S.Table isMini={checkboxSelection} isMobile={isMobile()}>
			<S.StyledDataGrid
				rows={results ? results : []}
				// @ts-ignore
				columns={columns}
				getRowHeight={() => 'auto'}
				getRowClassName={getStatusClassName}
				hideFooter={true}
				onPaginationModelChange={setPaginationModel}
				sortingMode="server"
				onSortModelChange={handleSortModelChange}
				disableRowSelectionOnClick={true}
				loading={loading}
				checkboxSelection={checkboxSelection}
				keepNonExistentRowsSelected={checkboxSelection}
				onRowSelectionModelChange={
					checkboxSelection ? onRowSelectionModelChange : undefined
				}
				rowSelectionModel={
					checkboxSelection ? rowSelectionModel : undefined
				}
				slots={
					isDragDropEnabled
						? {
								row: CustomRow
						  }
						: undefined
				}
			/>
		</S.Table>
	)

	const CustomRow = ({ row, index, ...props }) => (
		<Draggable draggableId={row.id.toString()} index={index}>
			{(provided) => {
				return (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={{
							...provided.draggableProps.style,
							position: 'static'
						}}
					>
						<GridRow {...props} row={row} index={index} />
					</div>
				)
			}}
		</Draggable>
	)

	useEffect(() => {
		getData()
	}, [getData])

	useEffect(() => {
		if (createNewContent) {
			setTimeout(() => {
				setCreateNewContent((currCreateNewContent) => {
					if (currCreateNewContent) {
						setSelectedContentId(null)
						setContentModalOpen(true)
					}
					return false
				}, 500)
			})
		}
	}, [createNewContent, setCreateNewContent])

	useEffect(() => {
		if (checkboxSelection && selectedIds !== null) {
			setRowSelectionModel(selectedIds.map(({ id }) => id))
		}
	}, [selectedIds, checkboxSelection])

	return (
		<S.Panel>
			{isDragDropEnabled ? (
				<DragDropContext onDragEnd={onLocalDragEnd}>
					<Droppable droppableId="droppable">
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<ManageContentTable />
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<ManageContentTable />
			)}
			<S.PaginationContainer>
				<CustomPagination
					totalResults={totalResults}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
				/>
			</S.PaginationContainer>
			<EditContentWrapper
				selectedContentId={selectedContentId}
				setSelectedContentId={setSelectedContentId}
				contentModalOpen={contentModalOpen}
				setContentModalOpen={setContentModalOpen}
				resetContentId={resetContentId}
				onUpdate={getData}
				onClose={onClose}
				skipPublishDialogOnFinish={skipPublishDialogOnFinish}
			/>
		</S.Panel>
	)
}

export default memo(ManageContent)
