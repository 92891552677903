import styled from 'styled-components'
import { Header, Message } from 'semantic-ui-react'
import { DataGrid } from '@mui/x-data-grid'

export const Panel = styled.div`
	border-radius: 4px;
`

export const Table = styled.div.attrs((props) => ({
	isMobile: props.isMobile
}))`
	width: 100%;
	${({ isMobile }) =>
		isMobile ? 'height: 100%' : 'height: calc(100vh - 300px);'}
	.MuiTablePagination-displayedRows {
		font-size: 13px;
		margin: 0;
	}
	.MuiSvgIcon-root {
		height: 25px;
		width: 25px;
	}
`

export const Info = styled(Header)`
	margin: 10px 0;
`

export const MessageFill = styled(Message)`
	.icon {
		color: #912d2b;
	}
`

export const StyledDataGrid = styled(DataGrid)({
	border: 0,
	color: 'rgba(0,0,0,.85)',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(','),
	WebkitFontSmoothing: 'auto',
	letterSpacing: 'normal',
	'& .MuiDataGrid-columnsContainer': {
		backgroundColor: '#fafafa'
	},
	'& .MuiDataGrid-iconSeparator': {
		display: 'none'
	},
	'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
		borderRight: `1px solid ${'#f0f0f0'}`
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `1px solid ${'#f0f0f0'}`
	},
	'& .MuiDataGrid-cell': {
		color: 'rgba(0,0,0,.85)',
		maxHeight: 'unset !important'
	},
	'& .MuiDataGrid-row': {
		maxHeight: 'unset !important'
	},
	'& .MuiPaginationItem-root': {
		borderRadius: 0
	},
	'& .MuiDataGrid-columnHeaders': {
		// backgroundColor: '#ededed'
	},
	'& .even-row': {
		backgroundColor: '#fafafb'
	},
	'& .MuiDataGrid-cellContent': {
		fontSize: 16
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontSize: 16,
		fontWeight: '700'
	},
	'& i': {
		fontSize: 15
	},
	'& .MuiDataGrid-root, .MuiDataGrid-cell': {
		whiteSpace: 'normal !important'
		// wordWrap: 'break-word !important'
	},
	'& .green-status': {
		background: '#e5ffe5 !important'
		// color: '#2c662d !important'
	},
	'& .green-status:hover': {
		background: '#e5ffe5 !important'
		// color: '#2c662d !important'
	},
	'& .status-cell': {
		whiteSpace: 'nowrap !important'
	},
	'& .yellow-status': {
		background: '#fffaf3 !important'
		// color: '#573a08 !important'
	},
	'& .yellow-status:hover': {
		background: '#fffaf3 !important'
		// background: '#ddfaf3 !important'
		// color: '#573a08 !important'
	},
	'& .red-status': {
		background: '#ffe5e5 !important'
		// color: '#662c2c !important'
	},
	'& .red-status:hover': {
		background: '#ffe5e5 !important'
		// color: '#662c2c !important'
	}
	// '& .MuiDataGrid-row:hover': {
	// 	backgroundColor: 'inherit !important'
	// },
	// '& .MuiDataGrid-row.Mui-hovered': {
	// 	backgroundColor: 'inherit !important'
	// }
})

export const PaginationContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 16px 0;
`
