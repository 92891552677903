import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'

import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import * as S from './styles'
import { collectionsActions } from '../../../../app/_actions'

import ManageContent from '../../../../pages/Dashboard/Content/ManageContent'
import CustomTabPanel from '../../CustomTabPanel'
import List from '../../../../pages/Dashboard/Content/List'
import EditDialogFooter from '../../EditDialogFooter'
import { STATUS } from '../../../../constants'
import { isMobile } from '../../../../utils/functions'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const EditCollectionContents = ({ collection, open, onClose }) => {
	const [createNewContent, setCreateNewContent] = useState(false)
	const [contentIndicationIds, setContentIndicationIds] = useState([])
	const [searchValue, setSearchValue] = useState('')
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const searchValueRef = useRef('')
	const dispatch = useDispatch()
	const { t } = useTranslation()

	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)
	const isAdmin = user ? user.roles.includes('ROLE_ADMIN') : false

	// eslint-disable-next-line
	const debounceContent = useCallback(
		debounce((value) => {
			setSearchValue(value)
		}, 500),
		[]
	)

	const handleContentSearch = useCallback(
		({ target }) => {
			debounceContent(target.value)
		},
		[debounceContent]
	)

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	const handleAddContent = () => {
		setCreateNewContent(true)
	}

	const handleSubmit = () => {
		dispatch(
			collectionsActions.updateCollection(collection.id, {
				content_indications: contentIndicationIds.map((item) => ({
					content_indication_id: item.id,
					order: item.order
				})),
				status: [STATUS.AGUARDANDO_APROVACAO, STATUS.APROVADO].includes(
					collection.status
				)
					? 4
					: collection.status
			})
		)
		onClose()
	}

	const handleOnUpdateContent = useCallback(
		({ selectedContentId }) => {
			if (
				selectedContentId &&
				!contentIndicationIds.includes(selectedContentId)
			) {
				setTimeout(() => {
					setContentIndicationIds([
						...contentIndicationIds,
						selectedContentId
					])
				}, 500)
			}
		},
		[contentIndicationIds, setContentIndicationIds]
	)

	const onDragEnd = ({ movedItemId, newIndex }) => {
		const movedContentIndicationIndex = contentIndicationIds.findIndex(
			({ id: contentIndicationId }) =>
				contentIndicationId === parseInt(movedItemId)
		)
		if (movedContentIndicationIndex === -1) {
			console.log(`Item with id ${movedItemId} not found in the list.`)
			return
		}

		const newContentIndicationList = contentIndicationIds.map((item) => ({
			content_indication_id: item.id,
			order: item.order
		}))

		const [movedContentIndicationId] = newContentIndicationList.splice(
			movedContentIndicationIndex,
			1
		)
		newContentIndicationList.splice(newIndex, 0, movedContentIndicationId)

		newContentIndicationList.forEach((item, index) => {
			item.order = index + 1
		})
		dispatch(
			collectionsActions.updateCollection(collection.id, {
				content_indications: newContentIndicationList
			})
		)
	}

	useEffect(() => {
		setContentIndicationIds(
			collection.content_indications.map(
				({ content_indication, order }) => ({
					id: content_indication.id,
					order
				})
			)
		)
	}, [collection.content_indications])

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
			<DialogTitle>
				<Box
					sx={
						isMobile()
							? {}
							: {
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: '100%',
									fontSize: '16pt'
							  }
					}
				>
					<span>
						Conteúdos d{collection.collection_type.genre}{' '}
						{collection.collection_type.name} {isMobile() && <br />}
						<i>
							(
							{contentIndicationIds.length === 1
								? `1 item selecionado`
								: `${contentIndicationIds.length} itens selecionados`}
							)
						</i>
					</span>
					<Button
						onClick={handleAddContent}
						startIcon={<AddIcon />}
						variant="contained"
					>
						Novo conteúdo
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<S.TabsContainer>
					<Tabs
						value={tabIndexValue}
						onChange={handleTabChange}
						indicatorColor="secondary"
					>
						<Tab label="Selecionar" {...a11yProps(0)} />
						<Tab label="Ver selecionados" {...a11yProps(1)} />
					</Tabs>
					<OutlinedInput
						sx={{ my: 1 }}
						endAdornment={
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						}
						onChange={handleContentSearch}
						placeholder={t(
							'collection.search-content',
							'buscar...'
						)}
					/>
				</S.TabsContainer>
				<CustomTabPanel value={tabIndexValue} index={0}>
					<ManageContent
						searchValue={searchValue}
						visibleFields={[
							'title',
							'cover_id',
							'author',
							'organization'
						]}
						searchValueRef={searchValueRef}
						isUserContent={!isAdmin}
						createNewContent={createNewContent}
						setCreateNewContent={setCreateNewContent}
						checkboxSelection={true}
						setSelectedIds={setContentIndicationIds}
						selectedIds={contentIndicationIds}
						skipPublishDialogOnFinish={true}
						onUpdate={handleOnUpdateContent}
						isDragDropEnabled={true}
						onDragEnd={onDragEnd}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={tabIndexValue} index={1}>
					<List contentIndicationIds={contentIndicationIds} />
				</CustomTabPanel>
			</DialogContent>
			<DialogActions>
				<EditDialogFooter
					showFooter={true}
					handleCloseModal={onClose}
					handleSubmit={handleSubmit}
					loading={false}
				/>
			</DialogActions>
		</Dialog>
	)
}

export default EditCollectionContents
