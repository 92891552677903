import styled from 'styled-components'
import { MenuList } from '@mui/material'

export const MenuWrapper = styled(MenuList).attrs((props) => ({
	is_wordpress_menu: props.is_wordpress_menu
}))`
	&.MuiList-root {
		position: fixed;
		height: calc(100vh - 68px);
		display: flex;
		flex-direction: column;
		overflow-x: auto;
		background: #fff;
		padding: 8px 0px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
	}
	a.MuiMenuItem-root {
		display: flex;
		padding: 12px 16px;
		align-items: center;
		gap: 8px;
		width: 200px;
		font-size: 14px;
		font-weight: 400;
		color: #00000099;
		line-height: 20px;
	}
	a.MuiMenuItem-root:last-of-type {
		margin-top: auto;
	}
	hr.MuiDivider-root {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	${({ is_wordpress_menu }) =>
		is_wordpress_menu === 'true' &&
		`
			&.MuiList-root {
				position: initial;
				height: auto;
				width: 100%;
				padding: 0;
				background-color: transparent;
			}
			a.MuiMenuItem-root {
				width: 100%;
				color: #ffffff
			}
		`}
	${({ is_wordpress_menu }) =>
		is_wordpress_menu == 'false' &&
		'@media screen and (max-width: 782px) { &.MuiList-root { display: none; }}'}
`
export const IconContainer = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`
export const ItemTitle = styled.span`
	margin-top: 2px;
`
